





































































































import { Component, Vue } from "vue-property-decorator";

import AbTestConfigurationModel from "../models/AbTestConfigurationModel";
import ConfigUtil from "../utils/ConfigUtil";

@Component
export default class AbTestEnablingDialog extends Vue {
  isMenuActiveSinceVisible = false;
  isMenuActiveTillVisible = false;
  activeSince = "";
  activeTill = "";
  isValid = true;

  get targetedConfig(): AbTestConfigurationModel {
    return this.$store.state.targetedConfig.enablingTargetedConfig;
  }

  get isDialogVisible(): boolean {
    return !!this.$store.state.targetedConfig.enablingTargetedConfig;
  }

  set isDialogVisible(newValue: boolean) {
    if (newValue) {
      return;
    }

    this.hideDialog();
  }

  get currentDate(): string {
    return ConfigUtil.getCurrentDate();
  }

  hideDialog() {
    this.$store.commit("setEnablingTargetedConfig");
  }

  enableTarget() {
    this.$store
      .dispatch("enableTargetedConfig", {
        targetedConfig: this.targetedConfig,
        date: {
          activeSince: this.activeSince || null,
          activeTill: this.activeTill || null,
        },
      })
      .then(() => {
        this.hideDialog();
      });
  }
}
