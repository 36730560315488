


























































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

import ConfigCurrentState from "@/ab-tests/components/ConfigCurrentState.vue";
import ConfigRulePreview from "@/ab-tests/components/ConfigRulePreview.vue";
import TargetedEnablingDialog from "@/ab-tests/components/TargetedEnablingDialog.vue";

import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import { AppSection } from "@/shared/models";
import { ConfigsStatus } from "@/ab-tests/models/ConfigsStatusModel";
import RuleModel, {
  RuleOperator,
  RulePreview,
} from "@/ab-tests/models/RuleModel";
import RuleUtil from "@/ab-tests/utils/RuleUtil";

@Component({
  components: {
    ConfigCurrentState,
    ConfigRulePreview,
    TargetedEnablingDialog,
  },
})
export default class TargetedView extends Vue {
  localTargetedConfig: TargetedConfigurationModel =
    new TargetedConfigurationModel();

  readonly AppSection = AppSection;
  readonly RuleOperator = RuleOperator;
  readonly STEPS = [
    ConfigsStatus.INIT,
    ConfigsStatus.SCHEDULED,
    ConfigsStatus.STARTED,
    ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
  ];

  get configId(): string {
    return this.$route.params.configId;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get targetedConfig(): TargetedConfigurationModel {
    return this.$store.state.targetedConfig.targetedConfig;
  }

  get loadingConfig(): boolean {
    return this.$store.state.targetedConfig.loadingConfig;
  }

  get localRules(): Array<RuleModel> {
    return [...this.localTargetedConfig.rules].sort((ruleA, ruleB) => {
      if (ruleA.operator === ruleB.operator) {
        return 0;
      }

      return ruleA.operator === RuleOperator.EXCLUDE ? -1 : 1;
    });
  }

  get isCurrentStateVisible(): boolean {
    return (
      !!this.localTargetedConfig.status &&
      [
        ConfigsStatus.INIT,
        ConfigsStatus.SCHEDULED,
        ConfigsStatus.STARTED,
        ConfigsStatus.CONFIG_DISTRIBUTION_FINISHED,
      ].includes(this.localTargetedConfig.status)
    );
  }

  get isDisableConfirmationNeeded(): boolean {
    return this.$store.state.targetedConfig.disableConfirmationNeeded;
  }

  @Watch("applicationId", { immediate: true })
  watchApplicationId() {
    this.$store.dispatch("getTargetedConfig", this.configId).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({ name: AppSection.TARGETED_CONFIGS });
      }
    });
  }

  @Watch("targetedConfig", { immediate: true })
  watchAbTest() {
    this.localTargetedConfig = TargetedConfigurationModel.of(
      this.targetedConfig
    );
  }

  handleRoutingToEdit() {
    this.$router.push({
      name: "targeted_edit",
      params: {
        configId: String(this.localTargetedConfig.id),
      },
    });
  }

  switchStatus(item: TargetedConfigurationModel, confirmed = false) {
    if (item.status !== ConfigsStatus.DISABLED) {
      this.$store.dispatch("disableTargetedConfig", {
        targetedConfig: item,
        confirmed,
      });
    } else {
      this.$store.dispatch("enableTargetedConfig", { targetedConfig: item });
    }
  }

  cancelConfirmation() {
    this.$store.commit("setDisableConfirmationNeeded", false);
  }

  getRulePreviews(rule: RuleModel): Array<RulePreview> {
    return RuleUtil.getRulePreviews(rule, this.$lang);
  }
}
